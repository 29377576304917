<script setup>
const props = defineProps({
  file: {
    type: Object,
    required: false,
    default() {
      return {}
    },
  },

  url: {
    type: String,
    required: false,
    default: null,
  },

  size: {
    type: String,
    required: false,
    default: null,
  },

  width: {
    type: [Number, String],
    required: false,
    default: null,
  },

  height: {
    type: [Number, String],
    required: false,
    default: null,
  },

  cover: {
    type: Boolean,
    required: false,
    default: false,
  },

  contain: {
    type: Boolean,
    required: false,
    default: false,
  },

  fetchpriority: {
    type: String,
    required: false,
    default: null,
  },

  lazy: {
    type: Boolean,
    required: false,
    default: true,
  },

  autoplay: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const { upload, sizes } = useUploadUtils()

const type = computed(() => {
  if (!props.file) {
    return
  }

  if (props.file.ext?.includes('svg')) {
    return 'icon'
  }

  if (props.file.mime?.includes('video')) {
    return 'video'
  }

  return 'image'
})

const component = computed(() => {
  if (type.value === 'icon') return resolveComponent('BaseAsyncIcon')
  if (type.value === 'video') return resolveComponent('BaseVideo')
  return resolveComponent('BaseImage')
})
</script>

<template>
  <component
    :is="component"
    :src="url ? url : upload(file, size)"
    :alternative-text="file?.alternativeText || file?.name"
    :width="width || sizes(file, size).width"
    :height="height || sizes(file, size).height"
    :cover="cover"
    :contain="contain"
    :fetchpriority="fetchpriority"
    :lazy="lazy"
    :file="file"
    :autoplay="autoplay"
  />
</template>
