<script setup>
const props = defineProps({
  src: {
    type: String,
    required: true,
    default() {
      return ''
    },
  },

  width: {
    type: [Number, String],
    required: false,
    default: null,
  },

  height: {
    type: [Number, String],
    required: false,
    default: null,
  },

  file: {
    type: Object,
    required: false,
    default() {
      return {}
    },
  },
})

const cache = new Map()

const root = ref(null)

onMounted(async () => {
  if (props.src && props.src.split('.').pop() === 'svg') {
    if (!cache.has(props.src)) {
      try {
        cache.set(
          props.src,
          fetch(props.src).then((r) => r.text()),
        )
      } catch (e) {
        cache.delete(props.src)
      }
    }

    if (cache.has(props.src)) {
      let innerHTML = await cache.get(props.src)

      if (innerHTML) {
        // Get the width and height from a svg string with regex and decimals
        const initialWidth = innerHTML.match(/width="(\d+(\.\d+)?)"/)[1]
        const initialHeight = innerHTML.match(/height="(\d+(\.\d+)?)"/)[1]

        innerHTML = innerHTML.replace(
          /<svg/g,
          `<svg viewBox="0 0 ${initialWidth} ${initialHeight}"`,
        )

        if (!innerHTML.includes('fill="')) {
          innerHTML = innerHTML.replace(/<svg/g, '<svg fill="currentColor"')
        }

        if (props.width) {
          innerHTML = innerHTML.replace(
            /width="[^"]+"/g,
            `width="${props.width}"`,
          )
        }

        if (props.height) {
          innerHTML = innerHTML.replace(
            /height="[^"]+"/g,
            `height="${props.height}"`,
          )
        }

        root.value.innerHTML = innerHTML.replace(
          /#([0-9a-f]{6})/i,
          'currentColor',
        )
      }
    }
  }
})
</script>

<template>
  <div ref="root"></div>
</template>
